<template>
  <div>
    <EditButton
      @func="openModal()"
      :disabled="!$atividades.permissoes.includes(14)"
    ></EditButton>
    <SideBar
      v-if="open"
      title="Editar colaborador"
      @hide="cancelar"
      :active="modalEditarColaborador"
      size="large"
      textSucces="Atualizar"
      :item="colaborador"
      icon="edit"
      :disabled="!$atividades.permissoes.includes(15)"
      @excluir="excluirColaborador()"
      @status="mudarStatusColaborador()"
      @success="submitForm()"
    >
      <div slot="body">
        <VuePerfectScrollbar
          class="scroll-edicao-colaborador"
          :settings="settings"
        >
          <div
            :id="'editarColaborador' + colaborador.id"
            class="vs-con-loading__container"
          >
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="w-full mt-3 mx-1">
                  <vs-input
                    label="Nome"
                    v-validate="'required'"
                    name="nome"
                    v-model="colaborador.nome"
                    class="w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('nome')"
                    >{{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mx-1 mt-3 w-full">
                  <vs-input
                    label="Email"
                    type="email"
                    v-validate="'required'"
                    name="email"
                    v-model="colaborador.email"
                    class="w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('email')"
                    >{{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
            </vs-row>

            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <label
                    style="color: black; font-size: 0.85rem"
                    class="m-0 pl-2"
                    >Perfil</label
                  >
                  <br />
                  <el-select
                    filterable
                    clearable
                    class="w-full"
                    v-validate="'required'"
                    placeholder="Selecione..."
                    name="perfil"
                    v-model="colaborador.id_perfil"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="perfil in perfis"
                      class="select-info"
                      :value="perfil.id"
                      :label="perfil.nome"
                      :key="perfil.id"
                    ></el-option>
                  </el-select>
                  <vs-row>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('perfil')"
                      >{{ $validators.empty }}</span
                    >
                  </vs-row>
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <label
                    style="color: black; font-size: 0.85rem"
                    class="m-0 pl-2"
                    >Tipo Emails</label
                  >
                  <br />
                  <el-select
                    multiple
                    collapse-tags
                    filterable
                    clearable
                    class="w-full"
                    placeholder="Selecione..."
                    v-model="tipoEmails"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="item in colaboradorBcc"
                      class="select-info"
                      :value="item.id"
                      :label="item.nome"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <label
                    style="color: black; font-size: 0.85rem"
                    class="m-0 pl-2"
                    >Empresa</label
                  >
                  <br />
                  <el-select
                    filterable
                    clearable
                    class="w-full"
                    v-validate="'required'"
                    placeholder="Selecione..."
                    name="empresa"
                    v-model="colaborador.id_empresa"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="empresa in empresas"
                      class="select-info"
                      :value="empresa.id"
                      :label="empresa.razao_social"
                      :key="empresa.id"
                    ></el-option>
                  </el-select>
                  <vs-row>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('empresa')"
                      >{{ $validators.empty }}</span
                    >
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    class="w-full"
                    type="date"
                    label="Data Nascimento"
                    v-model="colaborador.data_nascimento"
                  ></vs-input>
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    class="w-full"
                    type="text"
                    label="CNH"
                    v-model="colaborador.cnh"
                  ></vs-input>
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    label="CPF"
                    type="text"
                    masked="false"
                    v-mask="'###########'"
                    v-model="colaborador.cpf"
                    class="w-full"
                  />
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    class="w-full"
                    type="text"
                    label="RG"
                    v-model="colaborador.rg"
                  ></vs-input>
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    label="Telefone"
                    type="text"
                    masked="false"
                    v-model="colaborador.telefone"
                    class="w-full"
                  />
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    class="w-full"
                    type="text"
                    label="Endereço"
                    v-model="colaborador.endereco"
                  ></vs-input>
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              vs-w="12"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
              >
                <div class="mt-10">
                  <el-upload
                    accept=".jpg, .jpeg, .png, .gif"
                    :key="reRender"
                    :on-remove="removerFotoColaborador"
                    :action="link"
                    :auto-upload="true"
                    :on-error="erro"
                    :on-success="success"
                    class="w-full"
                    :limit="1"
                    :on-exceed="exceed"
                    :headers="headers"
                    :file-list="fileList"
                    name="image"
                    list-type="picture-card"
                    :on-preview="handlePreview"
                  >
                    <i class="el-icon-plus" style="font-size: 22px">Avatar</i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt />
                  </el-dialog>
                </div>
              </vs-col>
              <label style="color: #8c939d; font-size: 0.85rem" class="m-0 pl-3"
                >Somente 1 Imagem</label
              >
              <br />
            </vs-row>
          </div>
        </VuePerfectScrollbar>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  props: {
    colaborador: {
      required: true
    }
  },
  data() {
    return {
      parametros: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      fileList: [],
      perfis: [],
      modalEditarColaborador: false,
      originalData: {},
      error: false,
      dialogVisible: false,
      dialogImageUrl: "#",
      reRender: 0,
      link: "#",
      headers: {
        Authorization: "Bearer ".concat(localStorage.getItem("token")),
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Origin": "*"
      },
      open: false,
      empresas: {},
      colaboradorBcc: [
        { id: 0, nome: "Comercial (Levantamentos)" },
        { id: 1, nome: "PCO (Relatorio Serviços)" },
        { id: 7, nome: "PCO (Relatório Serviços - Avaliação)" },
        { id: 8, nome: "PCO (Comentários de Avaliação)" },
        { id: 2, nome: "PCO (Confirmação)" },
        { id: 3, nome: "Financeiro (Lembretes)" },
        { id: 4, nome: "Financeiro (Cobranca)" },
        { id: 5, nome: "Financeiro (Notas e Boletos)" },
        { id: 6, nome: "ADMIN (Colaboradores)" }
      ],
      tipoEmails: []
    };
  },
  mounted() {},
  methods: {
    async openModal() {
      this.parametros = await this.$parametros.get();
      this._beforeEditingCache = await Object.assign({}, this.colaborador);
      this.originalData = await this.colaborador;
      this.open = await true;
      this.modalEditarColaborador = await true;
      await this.$vs.loading({
        container: "#editarColaborador" + this.colaborador.id,
        scale: 0.6
      });
      try {
        this.link = await `${process.env.VUE_APP_URL}/avatar/${this.colaborador.id}`;
        await this.getTipoEmailsColaborador();
        await this.getPerfil();
        await this.getEmpresas();
        await this.mountFileList();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.reRender += await 1;
        await this.$vs.loading.close(
          "#editarColaborador" + this.colaborador.id + " > .con-vs-loading"
        );
      }
    },
    async mountFileList() {
      this.fileList = [];
      if (this.colaborador.foto != null) {
        this.fileList = await [
          {
            name: `${this.colaborador.nome}`,
            url: `${this.parametros.storage_cdn_url}/${this.colaborador.foto}`
          }
        ];
      }
    },
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.editarColaborador();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },
    async editarColaborador() {
      let colaborador = this.colaborador;
      delete colaborador.perfil;
      await this.$vs.loading({
        container: "#editarColaborador" + this.colaborador.id,
        scale: 0.6
      });
      try {
        const res = await this.$http.put(`colaborador/${this.colaborador.id}`, {
          colaborador,
          tipoEmails: this.tipoEmails
        });
        this.$vs.notify(this.$notify.Success);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Edição do colaborador N°" + res.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.modalEditarColaborador = false;
        await this.$vs.loading.close(
          "#editarColaborador" + this.colaborador.id + " > .con-vs-loading"
        );
      }
    },
    async excluirColaborador() {
      await this.$vs.loading({
        container: "#editarColaborador" + this.colaborador.id,
        scale: 0.6
      });
      try {
        await this.$http.delete(`colaborador/${this.colaborador.id}`);
        this.$vs.notify(this.$notify.Success);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "excluir",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Exclusão do colaborador N°" + this.colaborador.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.modalEditarColaborador = false;
        await this.$vs.loading.close(
          "#editarColaborador" + this.colaborador.id + " > .con-vs-loading"
        );
      }
    },
    async mudarStatusColaborador() {
      await this.$vs.loading({
        container: "#editarColaborador" + this.colaborador.id,
        scale: 0.6
      });
      try {
        const res = await this.$http.put(`colaborador/${this.colaborador.id}`, {
          colaborador: {
            lixeira: !this.colaborador.lixeira,
            email: this.colaborador.email
          },
          tipoEmails: null
        })
        this.$vs.notify(this.$notify.Success);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Mudar status do colaborador N°" + res.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.modalEditarColaborador = await false;
        await await this.$vs.loading.close(
          "#editarColaborador" + this.colaborador.id + " > .con-vs-loading"
        );
      }
    },
    async getPerfil() {
      await this.$vs.loading({
        container: "#editarColaborador" + this.colaborador.id,
        scale: 0.6
      });
      try {
        this.perfis = await this.$http.get(`perfil`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#editarColaborador" + this.colaborador.id + " > .con-vs-loading"
        );
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getTipoEmailsColaborador() {
      try {
        this.tipoEmails = [];
        const res = await this.$http.get(
          `getTipoEmailsColaborador/${this.colaborador.id}`
        );
        await res.forEach(async tipo => {
          await this.tipoEmails.push(tipo.tipo_email);
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async cancelar() {
      this.modalEditarColaborador = await false;
      await this.$emit("update");
    },
    async handlePreview(file) {
      this.dialogImageUrl = await file.url;
      this.dialogVisible = await true;
    },
    async success() {
      this.$vs.notify({
        title: "Sucesso",
        text: "Avatar Salvo com Sucesso!",
        color: "success"
      });
      await this.$emit("update");
    },
    async erro() {
      this.$vs.notify({
        title: "Ocorreu um erro",
        text: "Avatar não foi salvo! Tente novamente mais tarde!",
        color: "danger"
      });
      await this.$emit("update");
    },
    async exceed() {
      this.$vs.notify({
        title: "Tenha Calma!",
        text: "Somente 1 Imagem para o Avatar!",
        color: "danger"
      });
    },
    async removerFotoColaborador() {
      await this.$vs.loading({
        container: "#editarColaborador" + this.colaborador.id,
        scale: 0.6
      });
      try {
        await this.$http.delete(`deleteFotoColaborador/${this.colaborador.id}`);
        this.colaborador.foto = await null;
        await this.mountFileList();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#editarColaborador" + this.colaborador.id + " > .con-vs-loading"
        );
      }
    }
  },
  components: {
    ...components
  }
};
</script>
<style scoped>
.scroll-edicao-colaborador {
  max-height: 75vh !important;
}
</style>
