<template>
  <div>
    <div id="listaColaborador" class="vs-con-loading__container">
      <vs-table
        maxHeight="68vh"
        :data="colaboradores"
        search
        noDataText=""
        max-items="10"
        pagination
        id="tableColaborador"
      >
        <template slot="header">
          <vs-row vs-w="12">
            <vs-col vs-w="6">
              <h4 style="color: #b4aa99">Colaboradores do sistema</h4>
            </vs-col>
            <vs-col vs-w="6">
              <ModalCadastroColaborador @update="getColaboradores()" />
            </vs-col>
          </vs-row>
        </template>

        <template slot="thead">
          <vs-th sort-key="nome">Nome</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="perfil">Perfil</vs-th>
          <vs-th>Ações</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :state="data[indextr].lixeira == 1 ? 'danger' : ''"
            :key="indextr"
            v-for="(tr, indextr) in data"
          >
            <vs-td :data="data[indextr].nome">{{ data[indextr].nome }}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td :data="data[indextr].email">{{
              data[indextr].perfil
            }}</vs-td>
            <vs-td class="actions">
              <ModalEditarColaborador
                @update="getColaboradores()"
                :colaborador="data[indextr]"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div v-if="colaboradores.length == 0">
        <div class="con-colors">
          <ul>
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum colaborador cadstrado
              </h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCadastroColaborador from "./cadastro.vue"
import ModalEditarColaborador from "./editar.vue"
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      colaboradores: [],
      reRender: 0
    }
  },
  components: {
    ...components,
    ModalCadastroColaborador,
    ModalEditarColaborador
  },
  async mounted () {
    await this.getColaboradores()
  },
  methods: {
    async getColaboradores () {
      await this.$vs.loading({ container: "#listaColaborador", scale: 0.6 })
      try {
        this.colaboradores = await this.$http.get(`getColaboradorAndPerfil`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#listaColaborador > .con-vs-loading")
      }
    }
  },
};
</script>

<style lang="css">
.econtrol {
  color: #ffca00;
}
.col {
  width: auto;
}
.actions {
  width: 60px !important;
}
</style>
