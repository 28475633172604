<template>
  <div class="w-full">
    <SuccessButton
      class="mr-6"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="openModal()"
    ></SuccessButton>
    <SideBar
      :title="'CADASTRO DE COLABORADOR'"
      @hide="close()"
      :active="modalCadastrarColabodorador"
      textSucces="Salvar"
      @success="colaborador.id ? saveClose() : submitForm()"
      size="large"
    >
      <div slot="body">
        <VuePerfectScrollbar
          class="scroll-cadastro-colaborador"
          :settings="settings"
        >
          <div id="cadastroColaborador" class="vs-con-loading__container">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="w-full mt-3 mx-1">
                  <vs-input
                    label="Nome"
                    v-validate="'required'"
                    name="nome"
                    v-model="colaborador.nome"
                    class="w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('nome')"
                    >{{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mx-1 mt-3 w-full">
                  <vs-input
                    label="Email"
                    type="email"
                    v-validate="'required'"
                    name="email"
                    v-model="colaborador.email"
                    class="w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('email')"
                    >{{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <label
                    style="color: black; font-size: 0.85rem"
                    class="m-0 pl-2"
                    >Empresa</label
                  >
                  <br />
                  <el-select
                    filterable
                    clearable
                    class="w-full"
                    v-validate="'required'"
                    placeholder="Selecione..."
                    name="empresa"
                    v-model="colaborador.id_empresa"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="empresa in empresas"
                      class="select-info"
                      :value="empresa.id"
                      :label="empresa.razao_social"
                      :key="empresa.id"
                    ></el-option>
                  </el-select>
                  <vs-row>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('empresa')"
                      >{{ $validators.empty }}</span
                    >
                  </vs-row>
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <label
                    style="color: black; font-size: 0.85rem"
                    class="m-0 pl-2"
                    >Perfil</label
                  >
                  <br />
                  <el-select
                    filterable
                    clearable
                    class="w-full"
                    v-validate="'required'"
                    placeholder="Selecione..."
                    name="perfil"
                    v-model="colaborador.id_perfil"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="perfil in perfis"
                      class="select-info"
                      :value="perfil.id"
                      :label="perfil.nome"
                      :key="perfil.id"
                    ></el-option>
                  </el-select>
                  <vs-row>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('perfil')"
                      >{{ $validators.empty }}</span
                    >
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    class="w-full"
                    type="date"
                    label="Data Nascimento"
                    v-model="colaborador.data_nascimento"
                  ></vs-input>
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    class="w-full"
                    type="text"
                    label="CNH"
                    v-model="colaborador.cnh"
                  ></vs-input>
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    label="CPF"
                    v-model="colaborador.cpf"
                    type="text"
                    masked="false"
                    v-mask="'###########'"
                    class="w-full"
                  />
                </div>
              </vs-col>
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    class="w-full"
                    type="text"
                    label="RG"
                    v-model="colaborador.rg"
                  ></vs-input>
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <div class="mt-3 mx-1 w-full">
                  <vs-input
                    color="dark"
                    class="w-full"
                    type="text"
                    label="Endereço"
                    v-model="colaborador.endereco"
                  ></vs-input>
                </div>
              </vs-col>
            </vs-row>
            <vs-row
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              vs-w="12"
              v-if="colaborador.id"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
              >
                <div class="mt-10">
                  <el-upload
                    accept=".jpg, .jpeg, .png, .gif"
                    :action="link"
                    :auto-upload="true"
                    :on-error="erro"
                    :on-success="success"
                    :on-remove="removerFotoColaborador"
                    class="w-full"
                    :on-exceed="exceed"
                    :headers="headers"
                    :limit="1"
                    name="image"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                  >
                    <i class="el-icon-plus" style="font-size: 22px">Avatar</i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt />
                  </el-dialog>
                </div>
              </vs-col>
              <label style="color: #8c939d; font-size: 0.85rem" class="m-0 pl-3"
                >Somente 1 Imagem</label
              >
              <br />
            </vs-row>
          </div>
        </VuePerfectScrollbar>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  data () {
    return {
      colaborador: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      perfis: [],
      modalCadastrarColabodorador: false,
      error: false,
      link: "#",
      headers: {
        Authorization: "Bearer ".concat(localStorage.getItem("token")),
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Origin": "*"
      },
      file: {},
      dialogVisible: false,
      dialogImageUrl: null,
      empresas: {},
      app_host: `${process.env.VUE_APP_HOST}`,
    }
  },
  methods: {
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.cadastrarColaborador()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            })
          }
        })
      })
    },
    async openModal () {
      this.errors.clear()
      this.modalCadastrarColabodorador = await true
      await this.$vs.loading({ container: "#cadastroColaborador", scale: 0.6 })
      try {
        await this.getEmpresas()
        await this.getPerfil()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#cadastroColaborador > .con-vs-loading")
      }
    },
    async saveClose () {
      await this.$vs.loading({ container: "#cadastroColaborador", scale: 0.6 })
      try {
        await this.$http.put(`colaborador/${this.colaborador.id}`, {
          colaborador: this.colaborador,
          tipoEmails: null
        })
        this.$vs.notify.Success
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.colaborador = {}
        this.modalCadastrarColabodorador = false
        await this.$emit("update")
        await this.$vs.loading.close("#cadastroColaborador > .con-vs-loading")
      }
    },
    async close () {
      this.modalCadastrarColabodorador = false
      await this.$emit("update")
      this.colaborador = {}
    },
    async cadastrarColaborador () {
      await this.$vs.loading({ container: "#cadastroColaborador", scale: 0.6 })
      try {
        this.colaborador = await this.$http.post(
          "colaborador",
          {
            colaborador: this.colaborador,
            app_host: this.app_host,
          }
        )
        this.$vs.notify(this.$notify.Success)
        this.link = `${process.env.VUE_APP_URL}/avatar/${this.colaborador.id}`
        // const logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Cadastro do colaborador N°" + this.colaborador.id
        // }
        // await this.$logger(logData)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$emit("update")
        await this.$vs.loading.close("#cadastroColaborador > .con-vs-loading")
      }
    },
    async getPerfil () {
      try {
        this.perfis = await this.$http.get(`perfil`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getEmpresas () {
      try {
        this.empresas = await this.$http.get(`empresa`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async success (colaborador) {
      this.colaborador = colaborador
      this.$vs.notify({
        title: "Sucesso",
        text: "Avatar Salvo com Sucesso!",
        color: "success"
      })
    },
    async erro () {
      this.$vs.notify({
        title: "Ocorreu um erro",
        text: "Avatar não foi salvo! Tente novamente mais tarde!",
        color: "danger"
      })
    },
    async handlePictureCardPreview (file) {
      this.dialogImageUrl = await file.url
      this.dialogVisible = await true
    },
    async exceed () {
      this.$vs.notify({
        title: "Tenha Calma!",
        text: "Somente 1 Imagem para o Avatar!",
        color: "danger"
      })
    },
    async removerFotoColaborador () {
      await this.$vs.loading({
        container: "#cadastroColaborador",
        scale: 0.6
      })
      try {
        this.colaborador.foto = await this.$http.delete(
          `deleteFotoColaborador/${this.colaborador.id}`
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#cadastroColaborador > .con-vs-loading")
      }
    }
  },
  async mounted () { },
  components: components
};
</script>


<style lang="scss" scoped>
.scroll-cadastro-colaborador {
  max-height: 75vh !important;
}
</style>
